/* Core */
import Vue from 'vue'
import Buefy from 'buefy'
// import {VueCompositionAPI} from 'vue'

/* Router & Store */
import router from './router'
import store from './store'

/* Composition API */
// Vue.use(VueCompositionAPI)

/* Fetch sample data */
store.dispatch('fetch', 'clients')
store.dispatch('fetch', 'updates')

/*
* [Production notice]
* Please check Vue.render() samples starting at line 51 here.
* It's likely you may safely remove one of these imports in production:
* */
const AppDefault = () => import('./AppDefault.vue')
const AppLightDark = () => import('./AppLightDark.vue')

/* Default title tag */
const defaultDocumentTitle = 'Admin One Bulma Premium'

/* Detect mobile layout */
store.dispatch('layoutMobileToggle')

window.onresize = () => {
  store.dispatch('layoutMobileToggle')
}

/* Collapse mobile aside menu on route change & set title */
router.afterEach(to => {
  store.commit('asideMobileStateToggle', false)
  store.commit('overlayToggle', false)
  store.commit('asideActiveForcedKeyToggle', null)

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
})

Vue.use(Buefy)

new Vue({
  router,
  store,

  // Default style example
  // render (h) {
  //   return h(AppDefault)
  // },

  // Light mode example
  // render (h) {
  //   store.commit('appStyle', 'light-dark') // ... or change default in src/store/index.js
  //   return h(AppLightDark)
  // },

  // Dark mode example
  // render (h) {
  //   store.commit('appStyle', 'light-dark') // ... or change default in src/store/index.js
  //   store.commit('darkModeToggle', true) // ... or change default in src/store/index.js
  //   return h(AppLightDark)
  // },

  // Demo render() with default & light-dark styles
  render (h) {
    const queryString = window.location.search

    // Demo only. Iframe preview mode
    if (queryString.indexOf('iframe-preview-mode') > -1) {
      store.commit('iframePreviewMode', true)
    }

    // Demo only. Load proper style
    const appStyle = queryString.indexOf('style=light-dark') < 0 ? 'default' : 'light-dark'

    store.commit('appStyle', appStyle)

    if (appStyle === 'light-dark' && queryString.indexOf('style=light-dark-dark') > -1) {
      store.commit('darkModeToggle', true)
    }

    return h(appStyle === 'default' ? AppDefault : AppLightDark)
  }
}).$mount('#app')
