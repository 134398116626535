<template>
  <card-component
    :title="title"
    :icon="icon"
    is-scrollable
    has-button-slot
    has-footer-slot
  >
    <refresh-button
      slot="button"
      :is-loading="isLoading"
      @button-click="delayedFetch"
    />
    <card-toolbar
      slot="toolbar"
      class="is-upper"
      :class="(status.toolbarClass) ? status.toolbarClass : null"
    >
      <div
        v-if="status.text"
        slot="left"
      >
        {{ status.text }}
      </div>
      <span
        v-if="(status.label || status.labelIcon)"
        slot="right"
        class="tag"
        :class="status.labelClass"
      >
        <b-icon
          v-if="status.labelIcon"
          :icon="status.labelIcon"
          custom-size="default"
        />
        <span v-if="status.label">{{ status.label }}</span>
      </span>
    </card-toolbar>

    <div class="media-list">
      <b-loading
        :is-full-page="false"
        :active="isLoading"
      />
      <media-item
        v-for="item in items"
        :key="item.id"
        :item="item"
        :has-share-buttons="hasShareButtons"
        :has-dismiss="hasDismiss"
      />
    </div>

    <a
      slot="footer"
      class="card-footer-item"
      @click.prevent="delayedFetch"
    >
      <b-icon
        icon="autorenew"
        custom-size="default"
      />
      <span>Load more</span>
    </a>
  </card-component>
</template>

<script>
import axios from 'axios'
import CardComponent from '@/components/CardComponent.vue'
import CardToolbar from '@/components/CardToolbar.vue'
import MediaItem from '@/components/MediaItem.vue'
import RefreshButton from '@/components/RefreshButton.vue'

export default {
  name: 'CardScrollable',
  components: { RefreshButton, MediaItem, CardToolbar, CardComponent },
  props: {
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    dataUrl: {
      type: String,
      required: true
    },
    hasShareButtons: Boolean,
    hasDismiss: Boolean
  },
  data () {
    return {
      isLoading: false,
      items: [],
      status: {}
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.isLoading = true

      this.items = []

      this.status = {
        text: 'Fetching data...',
        labelClass: 'is-info',
        labelIcon: 'shuffle-variant'
      }

      axios
        .get(this.dataUrl)
        .then(r => {
          this.isLoading = false

          if (r.data) {
            if (r.data.data) {
              this.items = r.data.data
            }
            if (r.data.status) {
              this.status = r.data.status
            }
          }
        })
        .catch(e => {
          this.isLoading = false

          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
    },
    delayedFetch () {
      // This is an imitation, to show the loading state
      // We delay for 500ms

      this.isLoading = true

      this.items = []

      this.status = {
        text: 'Fetching data...',
        labelClass: 'is-info',
        labelIcon: 'shuffle-variant'
      }

      setTimeout(() => {
        this.fetchData()
      }, 500)
    }
  }
}
</script>
