<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar-main />
    <section class="section is-main-section">
      <tiles-block>
        <card-widget
          class="tile is-child"
          type="is-primary"
          icon="account-multiple"
          :number="512"
          :previous-number="384"
          previous-period="Mar, 2021"
          label="Clients"
        />
        <card-widget
          class="tile is-child"
          type="is-info"
          icon="cart-outline"
          :number="7770"
          :previous-number="7000"
          previous-period="Mar, 2021"
          prefix="$"
          label="Sales"
        />
        <card-widget
          class="tile is-child"
          type="is-success"
          icon="chart-timeline-variant"
          :number="256"
          :previous-number="384"
          previous-period="Mar, 2021"
          suffix="%"
          label="Performance"
        />
        <card-widget
          class="tile is-child"
          type="is-danger"
          icon="bell"
          :number="32"
          :previous-number="64"
          label="Alerts"
          previous-period="Mar, 2021"
        />
      </tiles-block>

      <card-component
        title="Performance"
        icon="finance"
        header-icon="reload"
        @header-icon-click="fillChartData"
      >
        <div
          v-if="chartData"
          class="chart-area"
        >
          <line-chart
            :chart-data="chartData"
            :chart-options="chartOptions"
            :style="{height: '100%'}"
          />
        </div>
      </card-component>

      <div class="columns is-desktop">
        <div class="column">
          <card-scrollable
            data-url="/data-sources/comments.json"
            title="Recent Comments"
            icon="comment-multiple-outline"
            has-share-buttons
          />
        </div>
        <div class="column">
          <card-scrollable
            data-url="/data-sources/stuff-updates.json"
            title="Updates"
            icon="animation-outline"
            has-dismiss
          />
        </div>
      </div>

      <card-component
        title="Clients"
        icon="account-multiple"
        class="has-table has-mobile-sort-spaced"
        has-button-slot
      >
        <refresh-button slot="button" />
        <card-toolbar slot="toolbar">
          <div
            slot="left"
            class="buttons has-addons"
          >
            <b-button
              active
              @click="actionSample"
            >
              Active
            </b-button>
            <b-button
              :type="isDarkModeActive ? 'is-white' : null"
              :outlined="isDarkModeActive"
              disabled
            >
              Recent
            </b-button>
            <b-button
              :type="isDarkModeActive ? 'is-white' : null"
              :outlined="isDarkModeActive"
              disabled
            >
              Archived
            </b-button>
          </div>
          <form
            slot="right"
            @submit.prevent="actionSample"
          >
            <div class="field has-addons">
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="Sample field..."
                >
              </div>
              <div class="control">
                <b-button
                  native-type="submit"
                  type="is-primary"
                >
                  <b-icon
                    icon="dots-horizontal"
                    custom-size="default"
                  />
                </b-button>
              </div>
            </div>
          </form>
        </card-toolbar>
        <clients-table-sample data-url="/data-sources/clients.json" />
      </card-component>
    </section>
  </div>
</template>

<script>
import * as chartConfig from '@/components/Charts/chart.config.js'
import TitleBar from '@/components/TitleBar.vue'
import TilesBlock from '@/components/TilesBlock.vue'
import CardWidget from '@/components/CardWidget.vue'
import CardComponent from '@/components/CardComponent.vue'
import LineChart from '@/components/Charts/LineChart.vue'
import ClientsTableSample from '@/components/ClientsTableSample.vue'
import HeroBarMain from '@/components/HeroBarMain.vue'
import CardToolbar from '@/components/CardToolbar.vue'
import CardScrollable from '@/components/CardScrollable.vue'
import RefreshButton from '@/components/RefreshButton.vue'
import { mapState } from 'vuex'

export default {
  name: 'HomeView',
  components: {
    RefreshButton,
    CardScrollable,
    CardToolbar,
    HeroBarMain,
    ClientsTableSample,
    LineChart,
    CardComponent,
    CardWidget,
    TilesBlock,
    TitleBar
  },
  data () {
    return {
      titleStack: ['Admin', 'Dashboard'],
      chartData: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          y: {
            display: false
          },
          x: {
            display: true
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }

    }
  },
  computed: {
    ...mapState([
      'isDarkModeActive'
    ])
  },
  mounted () {
    this.fillChartData()

    this.$buefy.snackbar.open({
      message: 'Welcome back',
      queue: false
    })
  },
  methods: {
    fillChartData () {
      this.chartData = chartConfig.sampleChartData()
    },
    actionSample () {
      this.$buefy.toast.open({
        message: 'Some action',
        type: 'is-info',
        queue: false
      })
    }
  }
}
</script>
